const SEO = [
	{
		page: "home",
		description:
			"I am a backend developer with expertise in Node.js. I have experience in building scalable, secure and reliable web applications using various frameworks and technologies.",
		keywords: ["Tommy", "Tommy Yan", "Yan"],
	},

	{
		page: "about",
		description:
			"I am a backend developer with expertise in Node.js. I have experience in building scalable, secure and reliable web applications using various frameworks and technologies.",
		keywords: ["Tommy", "Tommy Yan", "Yan"],
	},

	{
		page: "articles",
		description:
			"Chronological collection of my long-form thoughts on programming, leadership, product design, and more.",
		keywords: ["Tommy", "Tommy Yan", "Yan"],
	},

	{
		page: "projects",
		description:
			"I've worked on a variety of projects over the years and I'm proud of the progress I've made. Many of these projects are open-source and available for others to explore and contribute to.",
		keywords: ["Tommy", "Tommy Yan", "Yan"],
	},

	{
		page: "contact",
		description:
			"If you're looking for a result driven full stack engineer, feel free to reach out. I'm always open to new opportunities.",
		keywords: ["Tommy", "Tommy Yan", "Yan"],
	},
	{
		page: "experience",
		description:
			"I am a backend developer with expertise in Node.js. I have experience in building scalable, secure and reliable web applications using various frameworks and technologies.",
		keywords: [
			"Amazon",
			"Coda",
			"Capital One",
			"Veteran",
			"National Guard",
			"Army",
			"Air Force",
		],
	},
	{
		page: "education",
		description:
			"I received my Bachelor of Science in Computer Science from Rutgers University and Master of Science in Computer Science from Georgia Tech.",
		keywords: [
			"Georgia Tech",
			"Georgia Institute of Technology",
			"Rutgers University",
			"Rutgers",
			"Master of Science",
			"Bachelor of Science",
			"Computer Science",
		],
	},
];

export default SEO;
